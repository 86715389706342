.graph-wrapper {
    margin-top: 15px;
}

.active {
    font-weight: 600;
}

.apexcharts-svg {
  overflow: visible;
}
