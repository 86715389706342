.dateColumn {
    min-width: 200px;
}

.messageColumn {
    max-width: 800px;
    white-space: pre-wrap;
}

.errorColumn {
    min-width: 200px;
}

.criteria {
    padding: 5px;
    font-size: large;
}

.criteria-label {
    font-weight: 600;
}

.errorMessage {
    color: #800000;
}
