.card-valid {
    background-color: #a6c195;
    border-color: #0b2e13;
    color: #fff;
}

.card-gap {
    background-color: #c9371f;
    border-color: #2e0207;
    color: #fff;
}

.hidden {
    display: none;
}

.downArrow {
    height: 30px;
}

.overDifference {
    color: #931117;
}

.nav-link {
  cursor: pointer;
}
