.popup {
    background-color: #fff;
    margin: 3px;
    position: relative;
    z-index: 999;
}

.hidden {
    display: none;
}

.ball {
    cursor: default;
}

.prop {
    margin: 2px 2px 2px 5px;
    font-weight: 600;
}

.val {
    margin: 2px;
}
