.loader {
  position: fixed;
  top: 50%;
  left: 50%;
}

.blink-me {
  animation: blinker 4s linear infinite;
}

@keyframes blinker {
  50% {
    background-color: inherit;
  }
}