/* table {
    margin-top: 10px;
    border: #000 solid 1px;
    padding: 5px;
} */

.legend-dot {
    position: relative;
    top: -3px;
}
