.dateColumn {
    min-width: 200px;
}

.eventCodeColumn {
    max-width: 200px;
}

.eventColumn {
    min-width: 400px;
}

.criteria {
    padding: 5px;
    font-size: large;
}

.criteria-label {
    font-weight: 600;
}

.errorMessage {
    color: #800000;
}
