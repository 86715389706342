.FirmwareManagement {
  width: 100%;
  position: relative;

  .toolbarRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .filterRow {
    width: 100%;

    >div {
      display: flex;
      flex-wrap: wrap;
      
      >* {
        margin: 0.5em 2em;
      }
    }

    >div.reversed {
      >div >* {
        justify-content: flex-end;
        display: flex;
      }

      justify-content: flex-end;
    }

    .filterPanel {
      min-width: 25%;
      //max-width: 33%;
    }
  }

  .serialLink:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .hoverable {
    visibility: hidden;
  }

  *:hover {
    >*>.hoverable {
      visibility: visible;
      // opacity: 0.4;
      // &:hover {
      //   opacity: 1.0;
      // }
    }
  }
}