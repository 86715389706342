.Sims {
  margin: 12px 0;
  .SimInfo {
    display: flex;
    margin-left: 8px;
    >* {
      width: 33%;
    }
  }
  .Panel {
    > * {
      margin-bottom: 2px;
    }
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .Category {
    width: 100%;
    > * {
      display: flex;
      //justify-content: space-around;
    }
  }
  .Field {
    margin-left: 8px;
    width: 33%;
    font-size: small;
    display: flex;
    flex-direction: column;
    >label {
      font-weight: 500;
    }
    > span {
      margin-left: 16px;
    }
  }
}