@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);

.SearchContainer {
  width: 100%;
}

.SearchList {
  //display: inline;
  //float: left;
  // width: 30vw;
  max-width: 250px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .AdvancedButtons {
    display: flex;
    justify-content: space-between;
    margin: 10px -5px 0px 5px;
  }

  .SearchHeader {
    flex: 0 1 auto;
  }

  .parent {
    flex: 1 1 auto;
  }

  ul {
    list-style: none !important;
    padding: 0;
    margin: 0;

    > li {
      display: block;
      padding: 0;
      overflow-y: hidden;
    }
  }

  a {
    outline: 0;
    display: block;
    width: auto;
    margin: 0 5px 5px;
    padding: 8px;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
  }
}

.SearchResults {
  //display: inline;
  //float: left;
  //margin: 0 2.5% 0.5em 2.5%;
  //width: 100%;
  flex: 10 10 auto;
  min-width: 0;
  //background: #f3f3f3;
  //position: relative;
  //border-radius: 5px;
  //padding: 15px;
  //padding-left: 15px;
  //padding-right: 15px;
  //height: 800px;
}

.AdvancedSearch {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s ease-in-out;

  &.SearchOpen {
    visibility: visible;
    opacity: 1;
  }
}

.RatePlanPanel {
  width: 49%;
  margin-top: 15px;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  border: 1px rgb(221, 221, 221) solid;
}

.pull-right {
  float: right !important;
}

.bg-red,.bg-yellow,.bg-aqua,.bg-blue,.bg-light-blue,.bg-green,.bg-navy,.bg-teal,.bg-olive,.bg-lime,.bg-orange,.bg-fuchsia,.bg-purple,.bg-maroon,.bg-black,.bg-red-active,.bg-yellow-active,.bg-aqua-active,.bg-blue-active,.bg-light-blue-active,.bg-green-active,.bg-navy-active,.bg-teal-active,.bg-olive-active,.bg-lime-active,.bg-orange-active,.bg-fuchsia-active,.bg-purple-active,.bg-maroon-active,.bg-black-active,.callout.callout-danger,.callout.callout-warning,.callout.callout-info,.callout.callout-success,.alert-success,.alert-danger,.alert-error,.alert-warning,.alert-info,.label-danger,.label-info,.label-warning,.label-primary,.label-success,.modal-primary .modal-body,.modal-primary .modal-header,.modal-primary .modal-footer,.modal-warning .modal-body,.modal-warning .modal-header,.modal-warning .modal-footer,.modal-info .modal-body,.modal-info .modal-header,.modal-info .modal-footer,.modal-success .modal-body,.modal-success .modal-header,.modal-success .modal-footer,.modal-danger .modal-body,.modal-danger .modal-header,.modal-danger .modal-footer {
  color: #fff !important
}

.bg-gray {
  color: #000;
  background-color: #d2d6de !important
}

.bg-gray-light {
  background-color: #f7f7f7
}

.bg-black {
  background-color: #111 !important
}

.bg-red,.callout.callout-danger,.alert-danger,.alert-error,.label-danger,.modal-danger .modal-body {
  background-color: #dd4b39 !important
}

.bg-yellow,.callout.callout-warning,.alert-warning,.label-warning,.modal-warning .modal-body {
  background-color: #f39c12 !important
}

.bg-aqua,.callout.callout-info,.alert-info,.label-info,.modal-info .modal-body {
  background-color: #00c0ef !important
}

.bg-blue {
  background-color: #0073b7 !important
}

.bg-light-blue,.label-primary,.modal-primary .modal-body {
  background-color: #3c8dbc !important
}

.bg-green,.callout.callout-success,.alert-success,.label-success,.modal-success .modal-body {
  background-color: #00a65a !important
}

.bg-navy {
  background-color: #001f3f !important
}

.bg-teal {
  background-color: #39cccc !important
}

.bg-olive {
  background-color: #3d9970 !important
}

.bg-lime {
  background-color: #01ff70 !important
}

.bg-orange {
  background-color: #ff851b !important
}

.bg-fuchsia {
  background-color: #f012be !important
}

.bg-purple {
  background-color: #605ca8 !important
}

.bg-maroon {
  background-color: #d81b60 !important
}

.bg-gray-active {
  color: #000;
  background-color: #b5bbc8 !important
}

.bg-black-active {
  background-color: #000 !important
}

.bg-red-active,.modal-danger .modal-header,.modal-danger .modal-footer {
  background-color: #d33724 !important
}

.bg-yellow-active,.modal-warning .modal-header,.modal-warning .modal-footer {
  background-color: #db8b0b !important
}

.bg-aqua-active,.modal-info .modal-header,.modal-info .modal-footer {
  background-color: #00a7d0 !important
}

.bg-blue-active {
  background-color: #005384 !important
}

.bg-light-blue-active,.modal-primary .modal-header,.modal-primary .modal-footer {
  background-color: #357ca5 !important
}

.bg-green-active,.modal-success .modal-header,.modal-success .modal-footer {
  background-color: #008d4c !important
}

.bg-navy-active {
  background-color: #001a35 !important
}

.bg-teal-active {
  background-color: #30bbbb !important
}

.bg-olive-active {
  background-color: #368763 !important
}

.bg-lime-active {
  background-color: #00e765 !important
}

.bg-orange-active {
  background-color: #ff7701 !important
}

.bg-fuchsia-active {
  background-color: #db0ead !important
}

.bg-purple-active {
  background-color: #555299 !important
}

.bg-maroon-active {
  background-color: #ca195a !important
}

[class^="bg-"].disabled {
  opacity: .65;
  filter: alpha(opacity=65)
}

.text-red {
  color: #dd4b39 !important
}

.text-yellow {
  color: #f39c12 !important
}

.text-aqua {
  color: #00c0ef !important
}

.text-blue {
  color: #0073b7 !important
}

.text-black {
  color: #111 !important
}

.text-light-blue {
  color: #3c8dbc !important
}

.text-green {
  color: #00a65a !important
}

.text-gray {
  color: #d2d6de !important
}

.text-navy {
  color: #001f3f !important
}

.text-teal {
  color: #39cccc !important
}

.text-olive {
  color: #3d9970 !important
}

.text-lime {
  color: #01ff70 !important
}

.text-orange {
  color: #ff851b !important
}

.text-fuchsia {
  color: #f012be !important
}

.text-purple {
  color: #605ca8 !important
}

.text-maroon {
  color: #d81b60 !important
}

.link-muted {
  color: #7a869d
}

.link-muted:hover,.link-muted:focus {
  color: #606c84
}

.link-black {
  color: #666
}

.link-black:hover,.link-black:focus {
  color: #999
}

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.label-default {
  background-color: #d2d6de;
  color: #444;
}

.whitebg {
  background-color: #FFFFFF !important;
}

.redfg {
  color: #F50548;
}

.yellowfg {
  color: #F9CE2B;
}

.greenfg {
  color: #01C309;
}

.blackfg {
  color: #000000;
}

.bluefg {
  color: #51C6EA;
}

.color1fg {
  color: #E89294;
}

.orangefg {
  color: #ff9800;
}

.headerfg {
  color: #42baf1;
}

.greyfg {
  color: #a9aaad;
}

.list-group {
  padding-left: 0;
  margin-bottom: 20px;
  font-family: 'Source Sans Pro',sans-serif !important;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd
}

.SelectedItem {
  background-color: aliceblue;
}

.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px
}

a.list-group-item,button.list-group-item {
  color: #555
}

a.list-group-item .list-group-item-heading,button.list-group-item .list-group-item-heading {
  color: #333
}

a.list-group-item:focus,a.list-group-item:hover,button.list-group-item:focus,button.list-group-item:hover {
  color: #555;
  text-decoration: none;
  background-color: #f5f5f5
}

button.list-group-item {
  width: 100%;
  text-align: left
}

.list-group-item.disabled,.list-group-item.disabled:focus,.list-group-item.disabled:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #eee
}

.list-group-item.disabled .list-group-item-heading,.list-group-item.disabled:focus .list-group-item-heading,.list-group-item.disabled:hover .list-group-item-heading {
  color: inherit
}

.list-group-item.disabled .list-group-item-text,.list-group-item.disabled:focus .list-group-item-text,.list-group-item.disabled:hover .list-group-item-text {
  color: #777
}

.list-group-item.active,.list-group-item.active:focus,.list-group-item.active:hover {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7
}

.list-group-item.active .list-group-item-heading,.list-group-item.active .list-group-item-heading>.small,.list-group-item.active .list-group-item-heading>small,.list-group-item.active:focus .list-group-item-heading,.list-group-item.active:focus .list-group-item-heading>.small,.list-group-item.active:focus .list-group-item-heading>small,.list-group-item.active:hover .list-group-item-heading,.list-group-item.active:hover .list-group-item-heading>.small,.list-group-item.active:hover .list-group-item-heading>small {
  color: inherit
}

.list-group-item.active .list-group-item-text,.list-group-item.active:focus .list-group-item-text,.list-group-item.active:hover .list-group-item-text {
  color: #c7ddef
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8
}

a.list-group-item-success,button.list-group-item-success {
  color: #3c763d
}

a.list-group-item-success .list-group-item-heading,button.list-group-item-success .list-group-item-heading {
  color: inherit
}

a.list-group-item-success:focus,a.list-group-item-success:hover,button.list-group-item-success:focus,button.list-group-item-success:hover {
  color: #3c763d;
  background-color: #d0e9c6
}

a.list-group-item-success.active,a.list-group-item-success.active:focus,a.list-group-item-success.active:hover,button.list-group-item-success.active,button.list-group-item-success.active:focus,button.list-group-item-success.active:hover {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7
}

a.list-group-item-info,button.list-group-item-info {
  color: #31708f
}

a.list-group-item-info .list-group-item-heading,button.list-group-item-info .list-group-item-heading {
  color: inherit
}

a.list-group-item-info:focus,a.list-group-item-info:hover,button.list-group-item-info:focus,button.list-group-item-info:hover {
  color: #31708f;
  background-color: #c4e3f3
}

a.list-group-item-info.active,a.list-group-item-info.active:focus,a.list-group-item-info.active:hover,button.list-group-item-info.active,button.list-group-item-info.active:focus,button.list-group-item-info.active:hover {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3
}

a.list-group-item-warning,button.list-group-item-warning {
  color: #8a6d3b
}

a.list-group-item-warning .list-group-item-heading,button.list-group-item-warning .list-group-item-heading {
  color: inherit
}

a.list-group-item-warning:focus,a.list-group-item-warning:hover,button.list-group-item-warning:focus,button.list-group-item-warning:hover {
  color: #8a6d3b;
  background-color: #faf2cc
}

a.list-group-item-warning.active,a.list-group-item-warning.active:focus,a.list-group-item-warning.active:hover,button.list-group-item-warning.active,button.list-group-item-warning.active:focus,button.list-group-item-warning.active:hover {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede
}

a.list-group-item-danger,button.list-group-item-danger {
  color: #a94442
}

a.list-group-item-danger .list-group-item-heading,button.list-group-item-danger .list-group-item-heading {
  color: inherit
}

a.list-group-item-danger:focus,a.list-group-item-danger:hover,button.list-group-item-danger:focus,button.list-group-item-danger:hover {
  color: #a94442;
  background-color: #ebcccc
}

a.list-group-item-danger.active,a.list-group-item-danger.active:focus,a.list-group-item-danger.active:hover,button.list-group-item-danger.active,button.list-group-item-danger.active:focus,button.list-group-item-danger.active:hover {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3
}

.subscript {
  margin-top: -10px;
  margin-bottom: -3px;
}