.EditableField {
  .fieldText {
    min-width: 15px;
  }

  .hoverable {
    visibility: hidden;
  }

  *:hover {
    >.hoverable {
      visibility: visible;
      opacity: 0.4;
      &:hover {
        opacity: 1.0;
      }
    }
  }
}