.CurrentStatus {
  .FulfillmentHeader {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }
  header {
    font-weight: 550;
  }
  .ShipmentWorkspace {
    margin-bottom: 25px;
    display: flex;
    > * {
      width: 50%;
    }
    .OrderLines {
      display: flex;
        flex-direction: column;
      > * {
        display: flex;
        margin-bottom: 2px;
      }
      margin-bottom: 10px;
    }
    .DirectLines {
      width: 50%;
      margin-bottom: 10px;
    }
    .ComponentName {
      width: 50%;
    }
    .KitComponent {
      margin-left: 8px;
      font-size: smaller;
      display: flex;
      justify-content: space-between;
    }
    .CommandHeader {
      display: flex;
      > * {
        width: 33%;
      }
    }
    .PendShipment {
      border: 2px black solid;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      > div {
        display: flex;
        > div {
          > div {
            display: flex;
            > div {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .DevicePanel {
    margin-bottom: 25px;
    > * {
      width: 100%;
    }
    .Panel {
      > * {
        margin-bottom: 2px;
      }
      margin-left: 25px;
      margin-right: 25px;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    .Category {
      width: 100%;
      > * {
        display: flex;
        //justify-content: space-around;
      }
    }
    .Field {
      margin-left: 8px;
      width: 33%;
      font-size: small;
      display: flex;
      flex-direction: column;
      >label {
        font-weight: 500;
      }
      > span {
        margin-left: 16px;
      }
    }
  }
  &:hover {
    .hoverableClose {
      visibility: visible;
      opacity: 0.4;
      &:hover {
        opacity: 1.0;
      }
    }
  }
  .hoverableClose {
    visibility: hidden;
  }
  .flex-spacer {
    flex-grow: 1;
  }
}