.FirmwareDetails {
  width: 100%;

  .ChartSection {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    justify-content: space-around;

    .chart {
      >* {
        margin-bottom: 1em;
      }
      max-width: 50%;
      min-width: 35%;
      margin: 2em;
    }
  }  
  
  .hoverable {
    visibility: hidden;
  }

  *:hover {
    >*>.hoverable {
      visibility: visible;
      // opacity: 0.4;
      // &:hover {
      //   opacity: 1.0;
      // }
    }
  }
}