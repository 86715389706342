.DeviceSearch {
  .toolbarRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .filterRow {
    width: 100%;

    >div {
      display: flex;
      >* {
        margin: 0.5em 0.1em;
      }
    }

    .filterPanel {
      min-width: 10%;
      max-width: 20%;
    }
  }

  .serialLink:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .hoverable {
    visibility: hidden;
  }

  *:hover {
    >*>.hoverable {
      visibility: visible;
      opacity: 0.4;
      &:hover {
        opacity: 1.0;
      }
    }
  }
}