.VictimManagement {
  flex: 1 1 auto;

  .rc-tabs-tab {
    background: inherit;

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  .rc-tabs-tab-active {
    background: rgba(255, 255, 255, 0.5);
  }
}