.chart-wrap{
  width: var(--width, 400px);
  height: var(--height, 400px);
  font-family:sans-serif;
  
  .title {
    text-align:center; 
  }
}

.chart-subtitle {
  width: var(--width, 400px);
}

.grid {
  width:100%;
  height:100%;
  display:flex;
  flex-direction:row;
  justify-content:center;
  border-bottom:2px solid var(--grid-color, #aaa);
  background:repeating-linear-gradient(0deg,transparent,transparent 19.5%,fadeout(var(--grid-color, #aaa),30%) 20%);

  >.barGroup, >.bar {
    margin: auto 0;
    position: relative;

    .bar {
      margin:0 auto;
    }
  }

  .bar {
    background-color:var(--bar-color, #F16335);
    width:var(--bar-size, 45px);
    height: var(--bar-value);
    align-self:flex-end;
    border-radius:var(--bar-rounded, 3px) var(--bar-rounded, 3px) 0 0;
    position:relative;
    
    &.bar:hover{
      opacity:0.7;
    }
    
    &::after{
      content:attr(data-value);
      top:-3em;
      padding:10px;
      display:inline-block;
      white-space:nowrap;
      position:absolute;
      transform:rotate(-45deg);
    }
  }
  
  &.horizontal{
    flex-direction:column;
    //border-bottom:none;
    border-left:2px solid var(--grid-color, #aaa);
    background:repeating-linear-gradient(90deg,transparent,transparent 19.5%,fadeout(var(--grid-color, #aaa),30%) 20%);

     >.barGroup, >.bar {
      margin: auto 0;
      position: relative;
      .bar {
        margin: 0;
      }
    }

    >.barGroup::before {
      content:attr(data-name);
      top:initial;
      right:100%;
      padding:0 10px;
      display:inline-block;
      white-space:nowrap;
      position:absolute;
      transform:rotate(0deg);
      line-height:var(--bar-size, 45px);
    }
      
    .bar{
      height:var(--bar-size, 45px);
      width: var(--bar-value);
      align-self:flex-start;
      border-radius:0 var(--bar-rounded, 3px) var(--bar-rounded, 3px) 0;
      
      &::after{
        top:initial;
        left:100%;
        padding:0 10px;
        display:inline-block;
        white-space:nowrap;
        position:absolute;
        transform:rotate(0deg);
        line-height:var(--bar-size, 45px);
      }
    }
  }
}

.chart-axis {
  display: flex;
  justify-content: space-between;
  padding-right: 15%;
  color: var(--grid-color, #aaa);
  pointer-events: none;
}