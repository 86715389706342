.slick-slide {
  height: auto; // ← that must not be ignored
}
.slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
.slide-img-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: .4;
  z-index: 10;
}
.h-100 {
  height: calc(100vh - 98px) !important;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.d-lg-block {
  display: block!important;
}
.slider-light .slick-dots {
  position: absolute;
  bottom: 10px;
}
.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: .75rem 0 0;
  width: 100%;
}
.slider-light .slick-next, .slider-light .slick-prev {
  background: transparent;
  color: #fff;
  box-shadow: 0 0 0 0 transparent;
}