//use for color function
//https://una.im/css-color-theming/
.pie {
border-radius: 100%;
height: calc(var(--size, 300) * 1px);
overflow: hidden;
position: relative;
width: calc(var(--size, 300) * 1px);

  >.segment {
    --a: calc(var(--over50, 0) * -100%);
    --b: calc((1 + var(--over50, 0)) * 100%);
    --degrees: calc((var(--offset, 0) / 100) * 360);
    -webkit-clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
    clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
    height: 100%;
    position: absolute;
    transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
    transform-origin: 50% 100%;
    width: 100%;
    z-index: calc(1 + var(--over50));
    opacity: 0.5;
    pointer-events: none;
      display: flex;
      align-items: flex-start;
      justify-content: center;

    .pie_label_container {
      bottom: -20%;
      left: -20%;
      position: relative;
      transform: translate(0, 100%) rotate(calc((var(--offset) + var(--value) + 25) * 3.6 * -1deg));
      width: 25%;
      //text-align: center;
    }
    .pie_label {
      position: fixed;
      opacity: 0;
    }

    >.beforeContainer {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &:hover {
      opacity: 1;
      z-index: 2;

      .pie_label {
        opacity: 1;
        &::after {
          content: var(--label, '');
        }
      }
    }

    >.after,
    .before {
      background: var(--bg, #e74c3c);
      content: attr(data-label);
      height: 100%;
      position: absolute;
      width: 100%;
      //z-index: calc(3 + var(--over50));
      pointer-events: all;
      //opacity: 0.5;
    }

    .before {
      --degrees: calc((var(--value, 45) / 100) * 360);
      transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
      transform-origin: 50% 0%;
    }
  }
}