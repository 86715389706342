.DropZone {
  padding: 25px;
  background-color: lightgrey;
  border: 2px dashed darkgrey;
  color: grey;
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}