.History {
  margin: 12px 0;

  .ParticipantLine {
    margin-left: 8px;
    font-size: smaller;
    display: flex;
    justify-content: space-between;
    >div {
      width: 33%;
    }
  }
  .flex-spacer {
    flex-grow: 1;
  }
}