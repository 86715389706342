.VictimHeader {
  display: flex;
  justify-content: space-around;
  padding: 12px 0px;
  font-size: larger;
  >div {
    >label {
      margin-right: 10px;
      font-weight: bold;
    }
  }
}

.rc-tabs-dropdown-menu-item {
  cursor: pointer;
}